import TemplateService from '../services/template.service'

export const template = {
    namespaced:true,
    state:{
        page:1,
        templates:[],
        total_results:0
    },
    actions:{

        createTemplate({commit},template){
            
            return TemplateService.createTemplate(template).then(
                response => {
                    commit('createTemplate',response.data)
                    console.log(response)
                },
                error => {
                    console.log(error)
                }
            )
        },
        
        getTemplates({commit}, options = null) {
            return TemplateService.fetchTemplates(options).then(
                templates => {
                    commit('setTemplates',templates)
                },
                error => {
                    console.log(error)
                }
            )
        },

        deleteTemplate({commit},id){
            return TemplateService.deleteTemplate(id).then(
                response => {
                    console.log(response)
                    commit('deletedTemplate',id)
                },
                error => {
                    console.log(error)
                }
            )
        },

        updateTemplate({commit},template){
            return TemplateService.updateTemplate(template).then(
                response => {
                    console.log(response)
                    commit('updateTemplate',template)
                },
                error => {
                    console.log(error)
                }
            )
        }
    },
    mutations:{
        createTemplate(state,template){
            state.templates.push(template)
        },

        setTemplates(state,templates){
            state.templates = templates.results
            state.total_results = templates.totalResults
            state.page = templates.page
        },
        deletedTemplate(state,id){
            state.templates = state.templates.filter(template => template.id !== id)
        },
        updateTemplate(state,template){
            var index = state.templates.findIndex(e => e.id == template.id)
            state.templates[index] = template
        }        
    }
}