import instance from './interceptor'
const API_URL = process.env.VUE_APP_BASE_URL

class TemplateService{
    
    createTemplate(template){
        // make a post request wit
        return instance.post(API_URL + 'templates',template)

    } 

    fetchTemplates(){
        var params = {}
        return instance.get(API_URL + 'templates',{params: params}).then(response => {
            return response.data
        })
    }


    
    deleteTemplate(template_id) {
        return instance.delete(API_URL + 'templates/'+template_id).then(response => {
            return response.data
        })
    }

    updateTemplate(template){
        var newTemplate = {
            name:template.name,
            prompt:template.prompt,
        }
        return instance.put(API_URL + 'templates/'+template.id,newTemplate)
    }
    
}

export default new TemplateService()