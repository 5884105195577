<template>
  <div id="app">
    <Home/>
    <div class="container" style="max-width:100%">
      <router-view />
    </div>
  </div>
</template>

<script>
import Home from './components/Home.vue'

  export default{
    name: "App",
    components:{
      Home
    },
    computed:{
      currentUser(){
        return this.$store.state.auth.user
      }
    },
    methods:{
      logOut(){
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }
    }
  }
</script> 