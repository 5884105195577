<template>
  <div>
    <v-btn @click="currentComponent = 'Browse'">List Template</v-btn>
    <v-btn @click="currentComponent = 'Upload'">Upload Template</v-btn>
    <TemplateBrowse v-if="currentComponent === 'Browse'" :type="type"/>
    <TemplateUpload v-if="currentComponent === 'Upload'" :type="type"/>
  </div>
</template>

<script>
  import TemplateBrowse from '../views/TemplateBrowse.vue'
  import TemplateUpload from '../views/TemplateUpload.vue'

  export default {
    name: "Template",
    components: {
      TemplateBrowse,
      TemplateUpload
    },
    data() {
      return {
        currentComponent: 'Browse',
        type: 'template'
      }
    }
  }
</script>