<template>
  <div>
    <v-app id="inspire">
      <div>
        <v-data-table 
          :headers="headers" 
          :loading="loading" 
          :items="contents" 
          :options.sync="options"
          :server-items-length="total_results" 
          :footer-props="{
            'items-per-page-options': [20, 30, 40, 50, 100]
          }"
          item-key="name" 
          :search="search"
          class="elevation-1" 
          >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <v-row class="pa-6">
                                <!-- Filter for calories -->
                                <v-col cols="6">
                                  <v-select
                                    :items="items"
                                    v-model="typesFilterValue"
                                    label="Filter Types"
                                  ></v-select>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                    @keyup.enter="searchItems"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn color="error" @click="clearFilter">Clear</v-btn>
                                </v-col> 
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
          </template>
          <template v-slot:item.tags="{item}"> 
            <div style="max-width: 400px; word-wrap: break-word" @click="handleRowClick(item)">
              {{ item.tags }}
            </div>
          </template>
          <template v-slot:item.createdAt="{item}">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:body.append> </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
            <div style="display: flex; align-items: center;">
              <span>Indoor? </span>
              <v-checkbox 
                v-model="item.indoor"
                :append-icon="item.indoorLoading ? 'mdi-loading mdi-spin' : 'mdi-empty'"
                @change="handleCheckboxChanged(item, 'indoor')"
              ></v-checkbox>
            </div>
            <div style="display: flex; align-items: center;">
              <span>Background? </span>
              <v-checkbox 
                v-model="item.background"
                :append-icon="item.backgroundLoading ? 'mdi-loading mdi-spin' : 'mdi-empty'"
                @change="handleCheckboxChanged(item, 'background')"
              ></v-checkbox>
            </div>
          </template>
          
          <template v-slot:item.author="{item}">
            <v-text-field
              class="ma-2"
              v-model="item.author"
              :append-icon="item.authorLoading ? 'mdi-loading mdi-spin' : 'mdi-check'"
              @click:append="authorUpdateClicked(item)"
              solo
              placeholder="Enter Author Name"
              type="text"
            ></v-text-field>
          </template>
          <template v-slot:item.link="{item}">
            <v-text-field
              class="ma-2"
              v-model="item.link"
              :append-icon="item.linkLoading ? 'mdi-loading mdi-spin' : 'mdi-check'"
              @click:append="linkUpdateClicked(item)"
              solo
              type="text"
              placeholder="Enter Link"
            ></v-text-field>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-app>
    <v-dialog
      v-model="imagePreview"
      max-width="500"
    >
      <v-card v-if="imagePreview">
        <v-card-title class="text-h5">
          {{selectedRow.tags}}
          <div v-if="selectedRow.link">
            <a :href="selectedRow.link">Preview Original</a>
          </div>
          
        </v-card-title>

        <v-img
          :lazy-src=imageSrc(selectedRow)

          max-height="100%"
          max-width="100%"
          :src=imageSrc(selectedRow)
        ></v-img>

        <v-card-actions>
          <v-spacer></v-spacer>
            <div style="width: 1000px">
              <multiselect
                v-model="m_value"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                label="name"
                track-by="code"
                :options="m_options"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              />
            </div>
            <v-btn
            color="green darken-1"
            text
            @click="updateTags"
          >
            Submit
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="cancelClick"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  name: 'Template-Browse',
  props: {
    type: String,
    default: () => 'template'
  },
  components: {
    Multiselect
  },
  data() {
    return {
      loading:true,
      dialog: false,
      imagePreview:false,
      dialogDelete: false,
      deleteSelected:null,
      selectedRow:null,
      itemsPerPage:10,
      search: '',
      calories: '',
      contents: [],
      options: {},
      m_options: [],
      m_value: [],
      total_results: 0,
      categories:[],
      typesFilterValue: '',
      items: [
        { text: 'All', value: null},
        { text: 'Private', value: 'PRIVATE' },
        { text: 'Public', value: 'PUBLIC' },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Tags',
          align: 'start',
          sortable: false,
          value: 'tags',
          filter: this.tagFilter
        },
        {
          text: 'Type',
          value: 'access',
          filter: value => {
            console.log(value)
            return true
            // if (this.typesFilterValue === null) return true
            // return value === this.typesFilterValue
          }
        },
        { text: 'Created Date', value: 'createdAt' },
        {text:'Author',value:'author'},
        {text:'Link',value:'link'},
        { text: 'Actions', value: 'actions', sortable: false },
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options:{
      handler(){
        this.fetchItems()
      },
      deep:true
    },
    typesFilterValue(val) {
      console.log(val)
      this.options.page = 1
      var data = this.options
      data = Object.assign({access: val},data)
      this.fetchItems(data)
    }
  },
  created: function () {
    // this.$store.dispatch('content/getContents').then(() => {
    //   this.contents = this.$store.state.content.contents;
    // });
  },
  methods: {
    searchItems() {
        this.fetchItems()
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.m_options.push(tag);
      this.m_value.push(tag);
    },
    imageSrc(val) {
      return process.env.VUE_APP_BASE_URL+"media/"+val.thumbnail
    },
    fetchItems(data = null){
      this.loading = true
      if(data && this.typesFilterValue){
        if(data.access) {
          data.access = this.typesFilterValue
        }else {
          data = Object.assign({access: this.typesFilterValue},data)
        }
      }else if(this.typesFilterValue) {
        data = Object.assign({access: this.typesFilterValue},data)
      }
      if (this.search != "") {
        var searchItem = {
                tags: this.search,
            }
        data = Object.assign(searchItem,data)
      }
      let finalData = Object.assign(this.options,data)
      this.$store.dispatch('template/getTemplates',(finalData)).then(() => {
        this.contents = this.$store.state.template.templates;
        this.total_results = this.$store.state.template.total_results
        this.loading = false
      });
    },
    deleteItemConfirm() {
      this.closeDelete();
      this.$store.dispatch('template/deleteTemplate', this.deleteSelected.id).then(() => {
        this.contents = this.$store.state.template.templates;
      });
      this.deleteSelected = null
    },
    clearFilter() {
      this.search = ""
      this.typesFilterValue = ""
      this.options.page = 1
      delete this.options.tags
      delete this.options.access
      this.fetchItems()
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        
      });
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteSelected = item
      // this.$store.dispatch('content/deleteContent', item.id).then(() => {
      //   // this.contents = this.contents.filter(data => data.id != item.id)
      // });
    },
    authorUpdateClicked(item){
      item.authorLoading = true
      if(item.author){
        this.$store.dispatch('template/updateTemplate', item).then(() => {
          item.authorLoading = false
        })
      }else{
        item.authorLoading = false
      }
      console.log(item)
      
    },

    handleCheckboxChanged(item,type){
        console.log(item)
      if(type === 'indoor'){
        item.indoorLoading = true
        this.$store.dispatch('template/updateTemplate', item).then(() => {
          console.log("Called here")
          item.indoorLoading = false
        })
        
      }else if(type === 'background') {
        item.backgroundLoading = true
        this.$store.dispatch('template/updateTemplate', item).then(() => {
            item.backgroundLoading = false
        })
        
      }
    },
    getIcon(isLoading){
      return isLoading ? 'mdi-loading mdi-spin' : 'mdi-check-box-outline';
    },
    categoryUpdateClicked(item) {
      item.categoryLoading = true
      var categoryId = item.category.id
      delete item.category
      item.category = categoryId
      if(item.category) {
        this.$store.dispatch('template/updateTemplate', item).then(() => {
          item.categoryLoading = false
        })
      }else {
        item.cagtegoryLoading = false
      }
    },

    linkUpdateClicked(item){
      console.log(item)
      item.linkLoading = true
      if(item.author){
        this.$store.dispatch('template/updateTemplate', item).then(()=> {
          item.linkLoading = false
        })
      }else{
        item.linkLoading = false
      }
    },

    updateTags() {
      console.log(this.selectedRow)
      this.selectedRow.tags = this.m_value.map((tag) => {
        return tag.name
      })
      this.imagePreview = false

      this.$store.dispatch('template/updateTemplate', this.selectedRow).then(() => {
        console.log("Updated")
      })
    },

    cancelClick() {
      this.selectedRow = null
      this.imagePreview = false
    },

    handleRowClick(value) {
      console.log(value)
      this.selectedRow = value
      this.imagePreview = true
      const tags = this.selectedRow.tags.map((tag) => {
        return {
          name: tag,
          code: tag.substring(0, 2) + Math.floor(Math.random() * 10000000),
        }
      })
      this.m_options = [...tags]
      this.m_value = [...tags]
    }
  },
};
</script>