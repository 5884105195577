var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',{attrs:{"id":"inspire"}},[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.contents,"options":_vm.options,"server-items-length":_vm.total_results,"footer-props":{
          'items-per-page-options': [20, 30, 40, 50, 100]
        },"item-key":"name","search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Filter Types"},model:{value:(_vm.typesFilterValue),callback:function ($$v) {_vm.typesFilterValue=$$v},expression:"typesFilterValue"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchItems.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clearFilter}},[_vm._v("Clear")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.tags",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"400px","word-wrap":"break-word"},on:{"click":function($event){return _vm.handleRowClick(item)}}},[_vm._v(" "+_vm._s(item.tags)+" ")])]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"body.append",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_vm._v("Indoor? ")]),_c('v-checkbox',{attrs:{"append-icon":item.indoorLoading ? 'mdi-loading mdi-spin' : 'mdi-empty'},on:{"change":function($event){return _vm.handleCheckboxChanged(item, 'indoor')}},model:{value:(item.indoor),callback:function ($$v) {_vm.$set(item, "indoor", $$v)},expression:"item.indoor"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_vm._v("Background? ")]),_c('v-checkbox',{attrs:{"append-icon":item.backgroundLoading ? 'mdi-loading mdi-spin' : 'mdi-empty'},on:{"change":function($event){return _vm.handleCheckboxChanged(item, 'background')}},model:{value:(item.background),callback:function ($$v) {_vm.$set(item, "background", $$v)},expression:"item.background"}})],1)]}},{key:"item.author",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"append-icon":item.authorLoading ? 'mdi-loading mdi-spin' : 'mdi-check',"solo":"","placeholder":"Enter Author Name","type":"text"},on:{"click:append":function($event){return _vm.authorUpdateClicked(item)}},model:{value:(item.author),callback:function ($$v) {_vm.$set(item, "author", $$v)},expression:"item.author"}})]}},{key:"item.link",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"ma-2",attrs:{"append-icon":item.linkLoading ? 'mdi-loading mdi-spin' : 'mdi-check',"solo":"","type":"text","placeholder":"Enter Link"},on:{"click:append":function($event){return _vm.linkUpdateClicked(item)}},model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" No Data Available ")])]},proxy:true}])})],1)]),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.imagePreview),callback:function ($$v) {_vm.imagePreview=$$v},expression:"imagePreview"}},[(_vm.imagePreview)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.selectedRow.tags)+" "),(_vm.selectedRow.link)?_c('div',[_c('a',{attrs:{"href":_vm.selectedRow.link}},[_vm._v("Preview Original")])]):_vm._e()]),_c('v-img',{attrs:{"lazy-src":_vm.imageSrc(_vm.selectedRow),"max-height":"100%","max-width":"100%","src":_vm.imageSrc(_vm.selectedRow)}}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticStyle:{"width":"1000px"}},[_c('multiselect',{attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Search or add a tag","label":"name","track-by":"code","options":_vm.m_options,"multiple":true,"taggable":true},on:{"tag":_vm.addTag},model:{value:(_vm.m_value),callback:function ($$v) {_vm.m_value=$$v},expression:"m_value"}})],1),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.updateTags}},[_vm._v(" Submit ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelClick}},[_vm._v(" Close ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }