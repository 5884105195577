<template>
  <div>
    <v-app id="inspire">
      <div>
        <v-btn color="primary" @click="dialog = true">Add Templates</v-btn>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="templates"
          :options.sync="options"
          :server-items-length="total_results"
          :footer-props="{
            'items-per-page-options': [20, 30, 40, 50, 100],
          }"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:body.append> </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>

          <template v-slot:item.prompt="{ item }">
            <div
              style="max-width: 400px; word-wrap: break-word"
              @click="handleRowClick(item)"
            >
              {{ item.prompt }}
            </div>
          </template>
        </v-data-table>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Add Templates</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  label="Name"
                  v-model="form.name"
                  :rules="rules.name"
                  required
                ></v-text-field>
                <v-textarea
                  label="Prompt"
                  v-model="form.prompt"
                  :rules="rules.prompt"
                  required
                  filled
                  shaped
                  rows="5"
                  auto-grow
                ></v-textarea>
                <v-text-field
                  label="Order"
                  v-model="form.order"
                  :rules="rules.order"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="submitForm"> {{ selectedRow ? "Update" : "Save" }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "Browse",
  props: {
    type: String,
    default: () => "content",
  },
  data() {
    return {
      loading: true,
      dialog: false,
      imagePreview: false,
      dialogDelete: false,
      deleteSelected: null,
      selectedRow: null,
      itemsPerPage: 10,
      search: "",
      calories: "",
      templates: [],
      options: {},
      m_options: [],
      m_value: [],
      total_results: 0,
      categories: [],
      typesFilterValue: "",
      items: [
        { text: "All", value: null },
        { text: "Music", value: "music" },
        { text: "Picture", value: "picture" },
        { text: "Scene", value: "scene" },
        { text: "3DObject", value: "threeDObject" },
        { text: "Stickers", value: "threeDScene" },
        { text: "Zip", value: "zip" },
        { text: "Sticker", value: "sticker" },
      ],
      placements: [],
      form: {
        name: "",
        prompt: "",
        order: null,
        id: "",
        available: "",
        tags: []
      },
      rules: {
        order: [(val) => val > 0 || `Order value should be greater than 0!`],
        prompt: [(val) => (val || "").length > 0 || "This field is required"],
        name: [(val) => (val || "").length > 0 || "This field is required"],
      },
    };
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name", sortable: true },
        { text: "Order", value: "order" },
        { text: "Is Default", value: "isDefault" },
        { text: "Prompt", value: "prompt" },
        { text: "Available?", value: "available" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    selectedRow:{
      handler: function(newValue, oldValue){
        if(newValue != oldValue && newValue != null){
          this.$nextTick(() => {
          this.form.name = this.selectedRow.name;
          this.form.prompt = this.selectedRow.prompt;
          this.form.order = this.selectedRow.order;
        })
        }
        
      }
    }
  },
  created: function () {
    this.fetchTemplates();
  },
  methods: {

    fetchTemplates() {
      this.$store.dispatch("template/getTemplates").then(() => {
        this.templates = this.$store.state.template.templates;
        this.total_results = this.$store.state.template.total_results;
        this.loading = false;
      });
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        if(this.selectedRow){
          this.updateTemplates();
        }else {
          this.createTemplates();
        }
        
        this.dialog = false;
      }
    },

    createTemplates() {
      var template = {
        name: this.form.name,
        prompt: this.form.prompt,
        order: this.form.order,
      };
      this.$store.dispatch("template/createTemplate", template).then(() => {
        this.fetchTemplates();
      });
    },

    updateTemplates() {
      var template = {
        name: this.form.name,
        prompt: this.form.prompt,
        order: this.form.order,
        id: this.selectedRow.id,
      };
      this.$store.dispatch("template/updateTemplate", template).then(() => {
        this.fetchTemplates();
      });
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.$store
        .dispatch("template/deleteTemplate", this.deleteSelected.id)
        .then(() => {
          this.fetchTemplates();
        });
      this.deleteSelected = null;
    },
    close() {
      this.selectedRow = null;
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {});
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteSelected = item;
      // this.$store.dispatch('content/deleteContent', item.id).then(() => {
      //   // this.templates = this.templates.filter(data => data.id != item.id)
      // });
    },
   
  


    cancelClick() {
      this.selectedRow = null;
      this.imagePreview = false;
    },

    handleRowClick(value) {
      console.log(value);
      this.selectedRow = value;
      this.dialog = true

    },
  },
};
</script>
