import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './auth.module'
import {template} from './template.module'
import {user} from './user.module'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        auth,
        template,
        user
    },
    plugins:[createPersistedState()]
})