<template>
  <v-app>
    <v-container fluid>
      <v-card class="mx-auto" max-width="100%" outlined>
        <div v-for="(item, index) in content_upload" :key="item.count">
          <v-row align="center" no-gutters v-if="!item.complete">
            <v-col cols="2" class="pa-2">
              <v-text-field
                v-model="item.title"
                label="Title "
                solo
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="pa-2">
              <v-list-item-content>
                <div class="text-overline mb-4">Files Upload</div>
                <v-list-item-title class="text-h5 mb-1">
                  <v-file-input
                    accept="image/*"
                    show-size
                    label="Select Pano Image"
                    multiple
                    @change="filesAdded($event, index)"
                    :value="item && item.file != '' ? item.file : null"
                  ></v-file-input>
                </v-list-item-title>
                <v-list-item-subtitle
                  >Select One</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-col>
            <div style="width: 200px">
              <multiselect
                v-model="item.tags"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                label="name"
                track-by="code"
                :options="options"
                :multiple="true"
                :taggable="true"
                @tag="(newTag) => addTag(newTag, index)"
              />
            </div>
            <v-col cols="3" class="pa-2">
              <v-text-field
                v-model="item.author"
                label="Author Name"
                solo
                clearable
              ></v-text-field>
              <v-text-field
                v-model="item.link"
                label="Link Url"
                solo
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-checkbox
                v-model="item.indoor"
                label="Is indoor?"
                color="primary"
                hide-details>
              </v-checkbox>
              <v-checkbox
                v-model="item.background"
                label="Is background?"
                color="primary"
                hide-details>
              </v-checkbox>
            </v-col>
            <v-col cols="1" class="pa-2">
              <v-card-actions>
              <v-btn outlined rounded text color="primary" @click="upload(index)">
                Upload
              </v-btn>
              <v-icon
                color="pink"
                @click="removeItem(index)"
                v-if="content_upload.length > 1"
                >mdi-trash-can-outline</v-icon
              >
            </v-card-actions>
            </v-col>
            <v-progress-circular
              :value="item.progressValue"
              v-if="item.progress"
            ></v-progress-circular>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              v-if="item.processing"
            ></v-progress-circular>
          </v-row>
          <v-banner single-line v-else>
            <v-icon slot="icon" color="success" size="36"
              >mdi-checkbox-marked
            </v-icon>
            Completed Uploading {{ item.file.name }}
          </v-banner>
          <v-divider v-if="index !== content_upload.length - 1"></v-divider>
        </div>
      </v-card>
      <v-card-text style="height: 100px; position: relative">
        <v-fab-transition>
          <v-btn
            v-show="!hidden"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="addMore"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-text>
    </v-container>
    <v-card-text
      style="height: 100px; position: relative"
      v-if="content_upload.length > 1"
    >
      <v-fab-transition>
        <v-btn v-show="!hidden" color="pink" @click="uploadAll">
          Upload All
        </v-btn>
      </v-fab-transition>
    </v-card-text>
  </v-app>
</template>

<script>
import Multiselect from "vue-multiselect";
import Template from "../models/template";
import UploadService from "../services/template.service";
export default {
  name: "Upload",
  components: {
    Multiselect,
  },
  mounted: function () {
    this.content_upload.push(
      new Template([], "", null,false, false, 0, false, false, "")
    );
  },
  data() {
    return {
      content_upload: [],
      loading: false,
      selection: 1,
      accpet: "audio/*,image/*,video/*,.mp3,.scn,.obj,.zip,.usdz",
      thumbnail_accept: "image/*",
      hidden: false,
      options: [],
      value: [],
    };
  },
  methods: {
    addMore() {
      this.content_upload.push(
        new Template([], "", null,false, false, 0, false, false, "")
      );
    },
    removeItem(index) {
      console.log(index);
      this.content_upload.splice(index, 1);
    },
    addTag(newTag, index) {
        console.log(index)
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
      if (this.content_upload[index] && this.content_upload[index].tags == null) {
        this.content_upload[index].tags = [];
      }
      this.content_upload[index].tags.push(tag);
    },
    uploadContent(idx, content) {
      UploadService.createTemplate(content, (event) => {
        var progress = event.loaded / event.total;
        console.log(progress);
        if (progress == 1) {
          this.content_upload[idx].progress = false;
          this.content_upload[idx].processing = true;
        }
        this.content_upload[idx].progressValue = Math.round(
          (100 * event.loaded) / event.total
        );
      })
        .then(() => {
          var instance = this;
          setTimeout(() => {
            if (
              instance.content_upload.filter((item) => item.complete == false)
                .length == 0
            ) {
              instance.content_upload = [];
              instance.addMore();
            }
          }, 1000);
          this.content_upload[idx].processing = false;
          this.content_upload[idx].complete = true;
        })
        .catch(() => {
          this.content_upload[idx].processing = false;
          this.content_upload[idx].complete = false;
        });
    },

    filesAdded(files, index) {
      console.log(files);
      console.log(index);
      var instance = this;
      console.log(files);
      if (files && files.length > 0) {
        // for (var i = 0; i < files.length; i++) {
        //   instance.content_upload.push(new Content([], '', files[i], null, false, 0, false, false,null,null));
        // }
        // instance.content_upload.splice(index, 1);
        instance.content_upload[index].file = files[0];
        // instance.content_upload[index] = new Content([], 'picture', files[0], null, false, 0, false, false,null,null);
      }
    },

    upload(index) {
      this.content_upload[index].progress = true;
      this.uploadContent(index, this.content_upload[index]);
    },

    uploadAll() {
      for (var i = 0; i < this.content_upload.length; i++) {
        this.content_upload[i].progress = true;
        this.uploadContent(i, this.content_upload[i]);
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
