import axios from 'axios'
import store from './../store'
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // headers: {
    //     Authorization: store.getters["auth/getAccessToken"] ? `Bearer ${store.getters["auth/getAccessToken"]}` : ''
    // }
})

instance.interceptors.response.use(function(response){
    return response
},function(err){
    console.log(err)
    if(err.response.status === 401){
        localStorage.removeItem('user')
        return Promise.reject(err)
    }
})

instance.interceptors.request.use((request) => {
    let user = JSON.parse(localStorage.getItem('user'))
    let tokenExpiry = user.tokens.access.expires
    let now = new Date()
    let diff = new Date(tokenExpiry).getTime() - now.getTime()
    if(diff < 0){
        axios.post(process.env.VUE_APP_BASE_URL + 'auth/refresh-tokens',
        {refreshToken: user.tokens.refresh.token},
        {headers: {Authorization: 'Bearer '+ user.tokens.access.token}})
        .then(response => {
            user.tokens = response.data
            localStorage.setItem('user', JSON.stringify(user))
            store.dispatch('auth/tokenChanged',user)
        }).catch(err => {
            localStorage.removeItem('user')
            return Promise.reject(err)
        })
    }else{
        request.headers.Authorization = 'Bearer '+ store.getters["auth/getAccessToken"]
        return request
    }
    
})

export default instance