<template>
  <v-app>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search users"
      single-line
      hide-details
      class="no-flex"
      @keyup.enter="searchItems"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, 500, 1000]
      }"
    >
      <template v-slot:item.action="{ item }">
        <v-btn v-if="!item.filter" color="info" @click="showModal(item)"
          >Add Credits</v-btn
        >
        <v-btn
          v-if="item.filter"
          color="error"
          @click="(selectedRow = item), (deleteDialog = true)"
          >Remove from Filter</v-btn
        >
      </template>
      <template v-slot:item.tags="{ item }">
        <div
          style="max-width: 400px; word-wrap: break-word"
          @click="handleRowClick(item)"
        >
          {{ item.tags }}
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Credits</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-select
              :items="[1, 3, 5]"
              label="Add Credits"
              v-model="form.available_credits"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="submitForm">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        width="7"
      ></v-progress-circular>
    </v-overlay>
    
  </v-app>
</template>

<script>
export default {
  data: () => ({
    imagePreview: false,
    search: "",
    loading: true,
    options: {
        page: 1,
        itemsPerPage: 100
    },
    total_results: 0,
    headers: [
      { text: "Name", value: "name" },
      { text: "Username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Credits Remaining", value: "available_credits", sortable: true},
      { text: "Created Date", value: "createdAt" },
      { text: "Action", value: "action", sortable: false },
    ],
    rules: {
      required: (value) => !!value || "Required.",
    },
    items: [], // Fetch this from your API or use dummy data
    dialog: false,
    selectedRow: null,
    deleteDialog: false,
    totalItems: 0,
    form: {
        available_credits: "",
    },
  }),
  watch: {
    options: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
  methods: {
    showModal(item) {
      this.selectedRow = item;
      // You can do something with the item here if you want
      this.dialog = true;
    },

    fetchUsers() {
      this.$store.dispatch("user/getUsers", this.options).then(() => {
        this.items = this.$store.state.user.users;
        this.total_results = this.$store.state.user.total_results;
        this.totalItems = this.total_results
        this.loading = false;
      });
    },

    submitForm() {
      if (this.$refs.form.validate()) {
        this.createCredits()
        this.dialog = false;
      }
    },

    createCredits() {
      var options = {
        available_credits: this.form.available_credits,
        user_id: this.selectedRow.id,
      };
      this.$store.dispatch("user/updateCredit", options).then(() => {
        this.fetchUsers();
      });
    },

    searchItems() {
      if (this.search == "") {
        this.fetchUsers();
      } else {
        var options = {
          search: this.search,
        };
        this.$store.dispatch("user/searchUser", options).then(() => {
          this.items = this.$store.state.user.users;
          this.totalItems = this.items.length
        });
      }

      // Here you can do the search
      // You can use this.search to get the search query
    },
    handleRowClick(value) {
      this.selectedRow = value;
      this.imagePreview = true;
    },
  },
};
</script>

<style scoped>
.no-flex {
  flex: 0 1 auto;
}
</style>
