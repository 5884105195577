import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Browse from './views/Browse.vue'
import Template from './views/Template.vue'
import User from './views/User.vue'

Vue.use(Router)

export const router = new Router({
    routes: [
        {
            path:'/',
            name: 'Browse',
            component: Browse
        },
        {
            path:'/login',
            name: 'login',
            component: Login
        },
         {
            path: '/template',
            name: 'template',
            component: Template
         },
         {
            path: '/user',
            name: 'user',
            component: User
         }

    ]
})

router.beforeEach((to,from,next) => {
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = JSON.parse(localStorage.getItem('user'))
    next()
    if(authRequired && !loggedIn){
        return next('/login')
    }else{
        next()
    }
})