import UserService from '../services/user.service'

export const user = {
    namespaced:true,
    state:{
        page:1,
        users:[],
        total_results:0
    },
    actions:{
        getUsers({commit},options=null){
            console.log(options)
            return UserService.fetchUsers(options).then(
                users => {
                    commit('setUsers',users)
                },
                error => {
                    console.log(error)
                    
                }
            )
        },

        updateUser({commit},user){
            return UserService.updateUser(user).then(
                response => {
                    console.log(response)
                    commit('updateUser',user)
                },
                error => {
                    console.log(error)
                }
            )
        },

        searchUser({commit},options=null) {
            return UserService.searchUser(options).then(
                users => {
                    console.log(users)
                    commit('setSearchUsers',users)
                },
                error => {
                    console.log(error)
                }
            )
        },

        updateCredit({commit}, options){
            return UserService.updateCredits(options).then(
                response => {
                    console.log(response)
                    commit('updateCredits',options)
                },
                error => {
                    console.log(error)
                }
            )
        }
    },
    mutations:{
        setUsers(state,users){
            state.users = users.results
            state.total_results = users.totalResults
            state.page = users.page
        },
        setSearchUsers(state,users){
            state.users = users            
        },
        deletedContent(state,id){
            state.contents = state.contents.filter(content => content.id !== id)
        },
        updateContent(state,content){
            var index = state.contents.findIndex(e => e.id == content.id)
            state.contents[index] = content
        }
        
    }
}