import axios from 'axios'

const API_URL = process.env.VUE_APP_BASE_URL
class AuthService{
    login(user){
        return axios.post(API_URL + 'auth/login',{
            email: user.email,
            password: user.password
        }).then(response => {
            if(response.data.tokens){
                localStorage.setItem('user', JSON.stringify(response.data))
            }
            return response.data
        })
    }

    logout(){
        let user = JSON.parse(localStorage.getItem('user'))
        return axios.post(API_URL + 'auth/logout',{refreshToken:user.tokens.refresh.token}).then(response => {
            localStorage.removeItem('user');
            return Promise.resolve(response.data)
        }).catch(err => {
            localStorage.removeItem('user');
            localStorage.removeItem('contents')
            return Promise.reject(err)
        })
    }
}

export default new AuthService()