import instance from './interceptor'
const API_URL = process.env.VUE_APP_BASE_URL

class UserService{

    fetchUsers(options){   
        console.log(options)
        var params = {
            sortBy:'createdAt:asc',
            page:options.page,limit:((options.itemsPerPage < 1) ? 1000 : options.itemsPerPage),
        }
        if(options.sortBy.length > 0){
            for (const [key, value] of Object.entries(options.sortBy)) {
                console.log(key)
                params.sortBy = `${value}:desc`
            }
        }
        console.log(params)
        return instance.get(API_URL + 'users/',{params: params}).then(response => {
            return response.data
        })
    }

    searchUser(options){
        return instance.get(API_URL + `users/search/users?search=${options.search}`).then(response => {
            return response.data
        })
    }

    updateUser(user){
        var newUser = (({available_credit}) => ({available_credit}))(user)
        return instance.put(API_URL + 'user/'+user.id,newUser)
    }

    updateCredits(options){
        console.log(options)
        return instance.post(API_URL + 'credits/update',options).then(response => {
            return response.data
        })
    }
    
}

export default new UserService()